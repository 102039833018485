<template>
  <div class="pa-5">
    <div class="justify-center" style="background: #fff">
      <div class="mb-2 mt-4 survey-title">Encuesta para obtener el índice de Transformación Digital</div>
      <v-progress-linear
        :value="progress"
        :height="17"
        background-color="tway-gray"
        style="border-radius: 9px !important"
        color="#16C62E"
      />
      <div class="mt-2 survey-progress roboto">{{ progress }}%</div>
      <v-row v-if="desktop" class="ma-0 pb-4">
        <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5" class="ma-0 pa-0" justify="center" />
        <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7" class="pa-0 ma-0" align-self="center">
          <div class="survey-labels">
            <span class="roboto survey-intructions">Totalmente en desacuerdo</span>
            <span class="roboto survey-intructions">Totalmente de acuerdo</span>
          </div>
        </v-col>
      </v-row>
      <v-row v-if="desktop" class="ma-0 pt-2 pb-0">
        <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5" class="ma-0 pa-0" justify="center" />
        <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7" class="pa-0 px-4 ma-0" align-self="center">
          <answer :labels="true" :answers="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" />
        </v-col>
      </v-row>
    </div>

    <v-container fluid pa-0 v-if="loading">
      <v-row justify="end">
        <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6" />
      </v-row>

      <div v-for="counter in questions.length" :key="counter">
        <div v-if="counter - 1 == page">
          <v-row
            v-for="(question, qIndex) in questions[counter - 1]"
            :class="qIndex % 2 == 0 ? 'gray-bg' : ''"
            :key="qIndex"
            class="ma-0 py-4"
          >
            <v-col cols="12" xs="12" sm="12" md="5" lg="5" xl="5" class="ma-0 pa-0" justify="center">
              <div class="question-label">
                <span class="roboto question-number pa-7">{{ 5 * (counter - 1) + (qIndex + 1) }}</span>
                <span class="roboto question-text pr-8 text-align-left">
                  {{ question.title }}
                </span>
              </div>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="7" lg="7" xl="7" class="pa-0 px-4 ma-0" align-self="center">
              <answer
                :disabled="false"
                @response="setAnswer(question.id, $event.toString())"
                :answers="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              />
            </v-col>
          </v-row>
        </div>
      </div>
      <v-row class="ma-0 mt-8" :justify="mobile ? 'center' : 'end'" align="center">
        <a
          class="tway-violet--text text-decoration-underline roboto"
          style="font-size: 16px"
          v-if="desktop"
          :class="desktop ? 'mr-8' : 'mt-4'"
          @click.stop="dialog = true"
        >
          Completar más tarde
        </a>

        <v-dialog v-model="dialog" max-width="500">
          <v-card :class="desktop ? 'pa-10' : 'p-2'" style="border-radius: 4px !important">
            <v-card-title
              class="headline mb-3"
              :class="!desktop ? 'pt-8' : ''"
              :style="!desktop ? 'font-size: 20px !important;' : ''"
            >
              <v-row class="ma-0 pa-0" justify="center">
                <span>¿Seguro que quieres salir?</span>
              </v-row>
            </v-card-title>

            <v-card-text class="headline tway-gray-dark--text mb-4" style="font-size: 18px !important">
              <v-row class="ma-0 pa-0 text-center" justify="center">
                <span>Tu progreso quedará guardado hasta la pregunta Nº{{ lastQuestion }}</span>
              </v-row>
            </v-card-text>

            <v-card-actions>
              <v-spacer />
              <a
                class="button-text tway-violet--text"
                v-if="desktop"
                :class="desktop ? 'mr-16' : 'mt-2 mb-4'"
                @click.stop="dialog = false"
              >
                CANCELAR
              </a>
              <v-btn
                v-if="desktop"
                rounded
                medium
                color="tway-violet"
                class="button-text elevation-2 pa-6 white--text"
                @click.stop="
                  () => {
                    $router.replace({ name: 'home' });
                  }
                "
              >
                Sí, Volver al inicio
              </v-btn>
              <v-row v-if="!desktop" class="ma-0 pa-0" justify="center">
                <v-col cols="12">
                  <v-row class="pa-0 ma-0" justify="center">
                    <v-btn
                      rounded
                      medium
                      color="tway-violet"
                      class="button-text elevation-2 pa-6 white--text"
                      @click.stop="
                        () => {
                          $router.replace({ name: 'home' });
                        }
                      "
                    >
                      Sí, Volver al inicio
                    </v-btn>
                  </v-row>
                </v-col>
                <v-col cols="12">
                  <v-row class="pa-0 ma-0" justify="center">
                    <a
                      class="tway-violet--text"
                      v-if="!desktop"
                      :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
                      @click.stop="dialog = false"
                    >
                      CANCELAR
                    </a>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <div :style="!desktop ? 'width: 100%; display: flex; justify-content: center;' : ''">
          <v-btn
            style="height: 36px"
            rounded
            :disabled="levelAnswer"
            color="tway-violet"
            :class="!desktop ? 'mb-4' : ''"
            class="button-text elevation-8 white--text"
            @click="nextPage()"
          >
            <span style="height: 14px" v-if="page != questions.length - 1">Guardar y continuar</span>
            <span style="height: 14px" v-else>ver resultado</span>
          </v-btn>
        </div>
        <a
          class="tway-violet--text text-decoration-underline roboto"
          v-if="!desktop"
          :class="desktop ? 'mr-8' : 'mt-2 mb-4'"
          @click.stop="dialog = true"
        >
          Completar más tarde
        </a>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import DtService from "@/services/dtService";
import SurveyAnswer from "@/components/elements/SurveyAnswer";
import currentUserService from "@/services/currentUserService";

import { mapActions, mapGetters } from "vuex";

export default {
  name: "Survey",
  components: {
    answer: SurveyAnswer,
  },
  data: () => ({
    progress: 0,
    loading: false,
    dialog: false,
    page: 0,
    answers: {},
    response: null,
    current: 0,
    client: {},
    cliente: JSON.parse(localStorage.getItem("clientInfo")),
    surveyAttrs: {},
    questions: [[]],
    levelAnswer: true,
    user: {},
    lastQuestion: 0,
    data: {
      createdOn: null,
      description: null,
      enabled: null,
      expiredOn: null,
      goodbyeMessage: null,
      id: null,
      language: null,
      questions: [],
      title: null,
      welcomeMessage: null,
    },
  }),

  created() {
    this.setCompanyProfile();
    this.getCurrentUserData();
  },

  mounted() {
    this.isLoading = false;
  },

  methods: {
    ...mapActions("UserStore", ["SET_PERCENTAGE", "SET_ANSWERS", "setCompanyProfile"]),
    ...mapActions("authentication", ["setSidebarContent"]),
    async getCurrentUserData() {
      currentUserService
        .currentUserData()
        .then((userData) => {
          this.user = userData;
          DtService.getSurveyComplete("a123", this.user.id)
            .then((questions) => {
              this.SET_PERCENTAGE(Math.floor((questions.answered / questions.total) * 100));
              let temp = {};
              questions.answeredQuestions.forEach((q) => {
                temp[q] = "-";
              });

              this.SET_ANSWERS(temp);
              if (this.getPercentage == 100) {
                this.$router.replace({ name: "home" });
              }
              this.getSurvey("a123");
            })
            .catch((err) => {
              this.getSurvey("a123");
              this.$log.error(err);
            });
        })
        .catch((err) => this.$log.error(err));
    },
    setAnswer(id, answer) {
      this.answers[id] = answer;
      let flag = false;
      this.questions[this.page].forEach((q) => {
        if (this.answers[q.id] == undefined) {
          flag = true;
        }
      });
      this.levelAnswer = flag;

      Object.size = function (obj) {
        var size = 0,
          key;
        for (key in obj) {
          // eslint-disable-next-line no-prototype-builtins
          if (obj.hasOwnProperty(key)) {
            size++;
          }
        }
        return size;
      };

      this.progress = Math.floor((Object.size(this.answers) / this.data.questions.length) * 100);
    },
    nextPage() {
      let array = [];
      if (this.page < this.questions.length - 1) {
        // LLAMADA POR LAS RESPUESTAS
        this.questions[this.page].forEach((q) => {
          array.push({
            questionId: q.id,
            surveyId: this.data.id,
            title: q.title,
            context: q.description,
            answer: this.answers[q.id],
            userId: this.user.id,
            clientId: this.getCompany.id,
          });
        });
        DtService.postAnswers(array)
          .then(() => {
            let container = document.getElementById("main-scroll");
            container.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
            // AQUI ACTUALIZAR EL PROGRESO EN LOCAL STORAGE
            this.SET_PERCENTAGE(this.progress);
            this.SET_ANSWERS(this.answers);
          })
          .catch(
            (err) => (
              /* eslint-disable */ oo_oo(),
              /* eslint-disable */ console.log(...oo_oo(`760bf17f_0`, "error", err, `722c3bc_0`))
            )
          );

        this.page++;
        this.levelAnswer = true;
        window.scrollTo(0, 0);
      } else {
        // LLAMADA POR LAS ULTIMAS RESPUESTAS Y REDIRIJIR
        this.questions[this.page].forEach((q) => {
          array.push({
            questionId: q.id,
            surveyId: this.data.id,
            title: q.title,
            context: q.description,
            answer: this.answers[q.id],
            userId: this.user.id,
            clientId: this.getCompany.id
          });
        });
        DtService.postAnswers(array)
          .then(async () => {
            let container = document.getElementById("main-scroll");
            container.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth"
            });
            // AQUI ACTUALIZAR EL PROGRESO EN LOCAL STORAGE
            this.SET_PERCENTAGE(this.progress);
            this.SET_ANSWERS(this.answers);
            await DtService.getDti(this.user.id, this.data.id, false);
            this.$router.replace({
              name: "dashboard"
            });
          })
          .catch(
            (err) => (
              /* eslint-disable */ oo_oo(),
              /* eslint-disable */ console.log(...oo_oo(`760bf17f_1`, "error", err, `722c3bc_1`))
            )
          );
      }
    },
    getSurvey() {
      this.isLoading = true;
      DtService.getSurvey("a123")
        .then((questions) => {
          this.surveyAttrs = questions;
        })
        .catch((err) => this.$log.error(err))
        .then(() => (this.isLoading = false));
    }
  },
  watch: {
    dialog() {
      // Siempre la pagina anterior con la ultima pregunta es la que quedo guardada
      // y como la unica pagina que puede tener menos de 5 preguntas es la ultima, todas las demas siempre tendran 5
      // por lo que saber el numero de preguntas respondidas es multiplicar la cantidad de paginas menos uno por querer ver la pagina anterior (en este caso como las paginas empiezan desde cero no se resta)
      // en el caso de la primera pagina se guardará hasta la pregunta 0 -> 0 * 5 = 0 y corresponde
      this.lastQuestion = this.page * 5;
    },
    surveyAttrs() {
      this.data = this.surveyAttrs.content[0];
    },
    data() {
      //FIXME:
      const chunk = (arr, size) =>
        Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));
      let questions = this.data.questions;
      let index = 1;
      questions = questions.map((e) => {
        e.index = index;
        index++;
        return e;
      });
      function shuffle(array) {
        var currentIndex = array.length,
          temporaryValue,
          randomIndex;

        // While there remain elements to shuffle...
        while (0 !== currentIndex) {
          // Pick a remaining element...
          randomIndex = Math.floor(Math.random() * currentIndex);
          currentIndex -= 1;

          // And swap it with the current element.
          temporaryValue = array[currentIndex];
          array[currentIndex] = array[randomIndex];
          array[randomIndex] = temporaryValue;
        }
        return array;
      }
      if (this.getPercentage) {
        this.progress = this.getPercentage;
      } else {
        this.progress = 0;
      }
      let temp = JSON.parse(localStorage.getItem("answers"));
      if (temp && Object.values(temp).length != 0) {
        Object.keys(temp).forEach((key) => {
          this.answers[key] = temp[key];
        });
      } else {
        this.answers = {};
      }
      let answeredArray = [];
      let notAnsweredArray = [];
      questions.forEach((q) => {
        if (this.answers[q.id]) {
          answeredArray.push(q);
        } else {
          notAnsweredArray.push(q);
        }
      });
      notAnsweredArray = shuffle(notAnsweredArray);
      let finalArray = answeredArray;
      notAnsweredArray.forEach((q) => {
        finalArray.push(q);
      });
      this.questions = chunk(finalArray, 5);
      let flag = true;
      this.questions.forEach((array, i) => {
        array.forEach((q, questionIndex) => {
          if (flag && !this.answers[q.id]) {
            this.lastQuestion = i * 5 + questionIndex;
            this.page = i;
            flag = false;
            this.loading = true;
          }
        });
      });
    }
  },
  computed: {
    ...mapGetters("UserStore", ["getPercentage", "getCompany"]),
    ...mapGetters("authentication", ["getCorporative"]),
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    }
  }
};
</script>

<style lang="scss" scoped>
.v-input__control {
  width: 100% !important;
}
.survey-title {
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  line-height: 32px;
  color: #333333;
}
.survey-progress {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: #000000;
}
.survey-intructions {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #000000;
}
.v-label {
  width: 100px !important;
}
.question-text {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  align-items: center;

  /* Tway / Gris Oscuro */

  color: #333333;
}
.question-number {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  /* identical to box height, or 125% */

  /* Tway / Gris Oscuro */

  color: #333333;
}
.roboto {
  font-family: "Roboto Condensed", sans-serif !important;
}
.gray-bg {
  background-color: #f1f1f1;
}
.question-label {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.survey-labels {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  span {
    max-width: 120px;
    text-align: center;
  }
}
.text-align-left {
  width: 100%;
  text-align: left;
}
.v-banner {
  margin-top: -60px !important;
}
</style>
