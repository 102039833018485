<template>
  <div>
    <div v-if="!labels && desktop" class="survey-answer-container">
      <div
        @click="selectAnswer(i)"
        v-for="i in answers"
        :key="i.toString() + '-answer'"
        :class="response == i ? 'answer-round-selected' : 'answer-round'"
      >
        <div v-if="response == i" class="answer-round-fill tway-violet">
          <span class="pa-0 ma-0 tway-violet--text">{{ i }}</span>
        </div>
      </div>
    </div>
    <div v-if="!labels && mobile && lengthOverflow" class="survey-answer-container mb-4 mt-4">
      <div
        @click="selectAnswer(i)"
        v-for="i in chunk(answers, answers.length / 2)[0]"
        :key="i.toString() + '-answer'"
        class="answer-round-mobile"
      >
        <div v-if="response == i" class="answer-round-fill-mobile tway-violet elevation-8">
          <span class="pa-0 ma-0 white--text">{{ i }}</span>
        </div>
        <div v-if="response != i" class="answer-round-fill">
          <span class="pa-0 ma-0 roboto">{{ i }}</span>
        </div>
      </div>
    </div>
    <div v-if="!labels && mobile && lengthOverflow" class="survey-answer-container">
      <div
        @click="selectAnswer(i)"
        v-for="i in chunk(answers, answers.length / 2)[1]"
        :key="i.toString() + '-answer'"
        class="answer-round-mobile"
      >
        <div v-if="response == i" class="answer-round-fill-mobile tway-violet elevation-8">
          <span class="pa-0 ma-0 tway-violet--text">{{ i }}</span>
        </div>
        <div v-if="response != i" class="answer-round-fill">
          <span class="pa-0 ma-0 roboto">{{ i }}</span>
        </div>
      </div>
    </div>
    <div v-if="!labels && mobile && !lengthOverflow" class="survey-answer-container mb-4 mt-4">
      <div @click="selectAnswer(i)" v-for="i in answers" :key="i.toString() + '-answer'" class="answer-round-mobile">
        <div v-if="response == i" class="answer-round-fill-mobile tway-violet elevation-8">
          <span class="pa-0 ma-0 white--text">{{ i }}</span>
        </div>
        <div v-if="response != i" class="answer-round-fill">
          <span class="pa-0 ma-0 roboto">{{ i }}</span>
        </div>
      </div>
    </div>
    <div v-if="mobile" class="answer-puntuation-mobile pb-4 pt-7">
      <div class="roboto">{{ min }} = Totalmente en desacuerdo</div>
      <div class="roboto">{{ max }} = Totalmente de acuerdo</div>
    </div>
    <div v-if="labels && (mobile || desktop)" class="survey-answer-container">
      <div v-for="i in answers" :key="i.toString() + '-label'" class="answer-round-label">
        <span style="font-size: 20px" class="roboto answer-survey">{{ i }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SurveyAnswer",
  props: {
    answers: {
      type: [Array, Object],
      default: () => [],
      required: true,
    },
    labels: {
      type: Boolean,
      default: false,
      required: false,
    },
    disabled: {
      type: Boolean,
      default: false,
      required: false,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      response: null,
    };
  },
  methods: {
    chunk(arr, size) {
      return Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));
    },
    selectAnswer(answer) {
      if (!this.disabled) {
        this.response = answer;
        this.$emit("response", this.response);
      }
    },
  },
  computed: {
    mobile() {
      return this.$vuetify.breakpoint.sm || this.$vuetify.breakpoint.xs;
    },
    desktop() {
      return this.$vuetify.breakpoint.md || this.$vuetify.breakpoint.lg || this.$vuetify.breakpoint.xl;
    },
    lengthOverflow() {
      return this.answers ? this.answers.length >= 10 : false;
    },
  },
};
</script>

<style lang="scss" scoped>
.survey-answer-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
.answer-survey {
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 25px;
  color: #333333;
}
.answer-round {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border: 2px solid #666666;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.answer-round-selected {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border: 2px solid #7319d5;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.answer-round-mobile {
  cursor: pointer;
  width: 35px;
  height: 35px;
  border: 1px solid #666666;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.answer-round-fill-mobile {
  width: 100%;
  height: 100%;
  display: block;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 12px;
  }
}
.answer-puntuation-mobile {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  div {
    display: flex;
    justify-content: center;
    align-content: center;
    width: 50%;
    text-align: center;
    font-size: 16px;
  }
}
.answer-round-label {
  width: 23px;
  height: 23px;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #333333;
    font-size: 25px;
  }
}
.answer-round-fill {
  width: 13px;
  height: 13px;
  display: block;
  border-radius: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    font-size: 12px;
  }
}
</style>
